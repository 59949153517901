import React, {Component} from 'react'
import './work.less';
import Wrapper from '../components/Wrapper';
import PageTitle from "../components/PageTitle/index";
import Blurb from "../components/Blurb/index";
import BlogGrid from "../modules/BlogGrid/index";
import {Col, Pagination, Row} from "antd";
import { navigate } from '@reach/router';
import WorkListItem from "../components/WorkListItem";
import { graphql } from 'gatsby';

class Index extends Component {

    gotoPage(page){
        if(page===1){
            navigate(`/work`);
        } else {
            navigate(`/work/page/${page}`);
        }
    }
    render() {

        const page = this.props.data.wordpressPage;
        const work = this.props.data.allWordpressWpCaseStudy.edges;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <PageTitle
                    title={page.title}
                    image={page.acf.page_title_background.localFile.childImageSharp.original.src}
                />
                <Blurb
                    content={page.acf.blurb}
                />
                <div className="work-listing">
                    <Row type="flex">
                        {work && work.map(({node}) => {
                            return <Col xs={{span: 24}} md={{span: 12}} lg={{span: 8}} key={node.id}>
                                <WorkListItem
                                    work={node}
                                />
                            </Col>
                        })}
                    </Row>
                    <Row>
                        <Col xs={{span: 24}}>
                            <Pagination
                                current={this.props.pageContext.current}
                                total={this.props.pageContext.numPosts}
                                pageSize={this.props.pageContext.limit}
                                onChange={this.gotoPage}
                            />
                        </Col>
                    </Row>
                </div>
                <BlogGrid/>
            </Wrapper>
        )
    }
}

export const caseStudyListQuery = graphql`
  query caseStudyListQuery($skip: Int!, $limit: Int!) {
    wordpressPage(slug: { eq: "work" }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      acf {
        page_title_background {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
        blurb
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
      }
    },
    allWordpressWpCaseStudy(limit: $limit, skip: $skip, sort: { order: DESC, fields: [date] }){
      edges {
        node {
          wordpress_id
          id
          date
          slug
          title
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 350, maxHeight:215, quality: 90, cropFocus: CENTER) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }   
            }
            listing_blurb
          }
      }
    }
  }
 }
`;

export default Index
