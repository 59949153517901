import React from "react";
import "./style.less";
import Link from "../Link";
import Img from "gatsby-image";
import ReactHtmlParser from "react-html-parser";

class WorkListItem extends React.Component {
  render() {
    return (
      <Link
        to={"/work/" + this.props.work.slug + "/"}
        className="work-listing-item"
      >
        <span className="img-wrap">
          {this.props.work.acf.featured_image != null ? (
            <Img
              fluid={
                this.props.work.acf.featured_image.localFile.childImageSharp
                  .fluid
              }
            />
          ) : null}
        </span>
        <span className="content-wrap">
          <span className="title">
            {ReactHtmlParser(this.props.work.title)}
          </span>
          <span className="blurb">
            {ReactHtmlParser(this.props.work.acf.listing_blurb)}
          </span>
          <span className="arrow-link">See project</span>
        </span>
      </Link>
    );
  }
}

export default WorkListItem;
